<template>
  <div
    :class="$style.root"
    v-html="html"
  />
</template>

<script setup>
const props = defineProps({
  string: {
    type: String,
    required: true
  },
  forceTargetBlank: {
    type: Boolean,
    default: true
  }
})

const html = baseStringToHtml(props.string, props.forceTargetBlank)
</script>

<style module lang="scss">
  .root {}

  .root h1,
  .root h2,
  .root h3,
  .root h4,
  .root h5,
  .root h6 {
    font-size: inherit;
    line-height: inherit;
    font-style: inherit;
    font-weight: inherit;
  }

  .root h1 {
    margin-bottom: var(--lh, 1em);
    margin-top: 0;
    text-transform: uppercase;
  }

  .root h2,
  .root h3,
  .root h4,
  .root h5,
  .root h6 {
    margin-bottom: var(--lh, 1em);
    margin-top: 0;
    margin-left: calc(var(--unit--default) * 2);
  }

  .root a {
    color: inherit;
    text-decoration-thickness: inherit;
  }

  .root code {
    font-family: inherit;
  }

  .root ul,
  .root ol {
    padding: 0;
    margin: 0;
    margin-bottom: var(--lh, 1em);
    list-style-position: inside;
  }

  .root ol {
    list-style: none;
  }

  .root ol li,
  .root ul li {
    counter-increment: list-item;
    position: relative;
  }

  .root ol li {
    padding-left: 2ch;
  }

  .root ul li {
    padding-left: 2ch;
  }

  .root ol li::before {
    content: counter(list-item, decimal) ".\a0";
    position: absolute;
    top: 0;
    left: 0;
  }

  .root ul {
    list-style: none;
  }

  .root ul li::before {
    content: "–";
    position: absolute;
    top: 0;
    left: 0;
  }
/*
  .root ul li::marker {
    list-style-type: none;
    content: "• ";
    width: 10rem;
    display: block;
  } */

  .root p {
    margin-bottom: var(--lh, 1em);
    margin-top: 0;
  }

  .root p:last-of-type {
    margin-bottom: 0;
  }

  .root em,
  .root i {
  }

  .root u {
  }

  .root b,
  .root strong {
    font-weight: normal;
  }

  .root sup,
  .root sub {
    line-height: 0;
    font-size: 55%;
  }

  .root blockquote {
    /* font-size: var(--font-medium--font-size);
    line-height: var(--font-medium--line-height); */

    margin: 0;

    padding-left: calc(var(--unit--vertical) * 2);
    padding-right: calc(var(--unit--vertical) * 2);
    margin-bottom: var(--lh);
    margin-top: var(--lh);
  }
</style>
